import { TableHead, TableRow, TableCell, Box } from "@mui/material";
import moment from "moment";
import { MainTable, OrderStatusLabel, Pagination, TableBody, dateOnly } from "suregifts-component-lib";
import { useNavigate } from "react-router-dom";
import { OrderFilter, useGetOrders } from "../../../../services/order";

type TableViewProps = {
  filter: OrderFilter;
  handlerFilter: (params: any) => void;
};

function TableView({ filter, handlerFilter }: TableViewProps) {
  const { data, isFetching: isLoading } = useGetOrders(filter);
  const navigate = useNavigate();

  return (
    <div>
      <MainTable loading={isLoading} sx={{ marginTop: "2.5rem" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order No</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Sender Name</TableCell>
            <TableCell>Sender Email</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id} onClick={() => navigate(`${item.id}`)} sx={{ cursor: "pointer" }}>
              <TableCell>{item.orderNumber}</TableCell>
              <TableCell>{dateOnly(item.dateCreated)}</TableCell>
              <TableCell>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.SenderEmail}</TableCell>
              <TableCell>{item.senderName}</TableCell>
              <TableCell>{item.amountDue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handlerFilter({ page })} />
      </div>
    </div>
  );
}

export default TableView;
