import { CheckBalanceModel, useCheckBalance } from "../../../../services/vouchers";

import { VoucherInfoView } from "../../../../components/molecules/VoucherInfoView";
import { FilterDropdown, OptionNaming, VoucherStatusType, useAuthContext } from "suregifts-component-lib";
import { ReactNode, useEffect, useMemo } from "react";
import { RolesEnum } from "../../../../services/auth.api";
import { CheckBalanceForm } from "../../../../components/molecules/CheckBalanceForm";
import { useNavigate, useSearchParams } from "react-router-dom";
const baseUrl = "/merchant-backend/voucher-operations";
function CheckBalanceModal() {
  const { mutate: checkBalance, isLoading, data } = useCheckBalance();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const voucherCode = searchParams.get("voucherCode");

  useEffect(() => {
    if (!voucherCode) return;
    checkBalance({ voucherCode: voucherCode });
  }, []);

  const actionView: ReactNode | undefined = useMemo(() => {
    const options: OptionNaming[] = [];
    if (data) {
      if (data.statusId == VoucherStatusType.Active && data.isSuregifts && !data.email) {
        options.push({ text: "Assign Email", value: 1 });
      }
      if (user?.role === RolesEnum.SENIOR_SUPPORT || user?.role === RolesEnum.ADMIN || user?.role === RolesEnum.SUPER_ADMIN) {
        if (data.email) {
          options.push({ text: "Transfer", value: 2 });
        }
        if (data.statusId !== VoucherStatusType.Blacklisted) {
        }
        options.push({ text: "Deactivate", value: 4 });
        options.push({ text: "Reactivate", value: 5 });
        options.push({ text: "Replace", value: 6 });
        options.push({ text: "Swap", value: 7 });

        options.push({ text: "Blacklist", value: 3 });
      }
    }
    const dropdown = (
      <FilterDropdown
        onChange={(index, value) => {
          if (value == 1) {
            navigate(`${baseUrl}/assign-email/${data!.voucherCode}`);
          } else if (value == 2) {
            navigate(`${baseUrl}/transfer/${data!.voucherCode}`);
          } else if (value == 3) {
            navigate(`${baseUrl}/blacklist/${data!.voucherCode}`);
          } else if (value == 4) {
            navigate(`${baseUrl}/deactivate/${data!.voucherCode}`);
          } else if (value == 5) {
            navigate(`${baseUrl}/reactivate/${data!.voucherCode}`);
          } else if (value == 6) {
            navigate(`${baseUrl}/replace/${data!.voucherCode}`);
          } else if (value == 7) {
            navigate(`${baseUrl}/swap/${data!.voucherCode}?giftId=${data?.giftId}`);
          }
        }}
        display="Actions"
        options={options}
      />
    );
    return options.length > 0 ? dropdown : undefined;
  }, [data]);
  const handleCheckBalance = (model: CheckBalanceModel) => {
    checkBalance(model);
  };
  if (data) {
    return <VoucherInfoView info={data} actionView={actionView} />;
  }
  return <CheckBalanceForm isLoading={isLoading} checkBalance={handleCheckBalance} />;
}

export default CheckBalanceModal;
