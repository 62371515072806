import { Box, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OrderInfoData } from "../../../../services/order";
import { FormButton, OrderStatusLabel, OrderStatusType, PageHeaderTitle, TitleDetailView, TransactionStatusLabel, dateOnly } from "suregifts-component-lib";

export function OrderInfoView({ data }: { data?: OrderInfoData }) {
  const navigate = useNavigate();
  return (
    <div>
      <PageHeaderTitle title="Order Details" />
      <Box p="1.85rem" sx={{ mt: "1.25rem", bgcolor: "primary.main", boxShadow: 2, borderRadius: "1.25rem" }}>
        <Stack spacing={"1.85rem"}>
          <Stack direction={"row"} spacing={3}></Stack>
          <Stack spacing={3} direction={"row"}>
            <TitleDetailView title="Sender's Name" detail={data?.senderName} />
            <TitleDetailView align="right" title="Sender's Email" detail={data?.SenderEmail} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Sender's Phone" detail={data?.SenderPhone || "N/A"} />
            <TitleDetailView align="right" title="Date Created" detail={dateOnly(data?.dateCreated)} />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Total Value" detail={data?.subTotal} />
            <TitleDetailView
              align="right"
              title="Payment Status"
              detail={<TransactionStatusLabel text={data?.paymentStatus} status={data?.paymentStatusId} />}
            />
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Amount Due" detail={data?.amountDue} />
          </Stack>
        </Stack>
        <Divider sx={{ my: "2.5rem" }} />
        <Stack spacing={3}>
          <Stack direction={"row"} spacing={3}>
            <TitleDetailView title="Order Status" detail={<OrderStatusLabel label={data?.status} status={data?.statusId} />} />
            <TitleDetailView align="right" title="Delivery Status" detail={data?.deliveryStatus} />
          </Stack>
          {data?.statusId !== OrderStatusType.Canceled && (
            <Stack spacing={2} direction={"row"}>
              <FormButton sx={{ px: "0.125rem" }} fullWidth label={"Cancel Order"} color="primary" onClick={() => navigate("cancel")} />
            </Stack>
          )}
        </Stack>
      </Box>
    </div>
  );
}
