import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, Stack, DialogActions } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DialogTitle, FormSelect, FormInput, FormButton, YupShape } from "suregifts-component-lib";
import { useGetMerchantNames, useGetSwapMerchants } from "../../../../services/merchants";
import { SwapVoucherModel, useSwapVoucher } from "../../../../services/vouchers";
import * as yup from "yup";
const schema = yup
  .object<YupShape<SwapVoucherModel>>({
    voucherCode: yup.string().required(),
    giftId: yup.string().required(),
    feePercent: yup.number().required().min(0).max(100),
  })
  .required();
function SwapVoucherModal() {
  const [searchParams] = useSearchParams();
  const { voucherCode } = useParams();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SwapVoucherModel>({
    defaultValues: {
      giftId: "",
      voucherCode,
      feePercent: "10",
    },
    resolver: yupResolver(schema),
  });
  const { data: merchants } = useGetSwapMerchants(searchParams.get("giftId"));

  const { mutate: redeem, isLoading } = useSwapVoucher();
  const navigate = useNavigate();
  const handleClose = () => {
    if (isLoading) return;
    navigate("..");
  };
  const submitForm = (data) => {
    redeem(data, {
      onSuccess: () => {
        toast.success("Voucher swapped successfully.");
        handleClose();
      },
    });
  };
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title="Swap Voucher" handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput disabled label="Voucher Code" {...register("voucherCode")} helperText={errors.voucherCode?.message} />
          <Controller
            control={control}
            name="giftId"
            render={({ field }) => (
              <FormSelect
                required
                helperText={errors.giftId?.message}
                {...field}
                label={"Merchant"}
                options={merchants?.map((item) => ({
                  id: item.giftId,
                  name: item.name,
                }))}
              />
            )}
          />

          <FormInput required value={watch("feePercent")} label="Fee(%)" {...register("feePercent")} helperText={errors.feePercent?.message} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton disabled={!isValid} label="Send" fullWidth loading={isLoading} onClick={handleSubmit(submitForm)} />
      </DialogActions>
    </Dialog>
  );
}

export default SwapVoucherModal;
